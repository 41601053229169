<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="750px"
    content-class="rounded-xl"
  >
    <v-overlay class="justify-center align-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Upload Documents</div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="uploadDocumentDialog.caseId" class="pa-0">
        <div class="mt-6 mx-5">
          <v-form
            class="d-flex justify-space-between"
            style="gap: 20px"
            lazy-validation
            ref="form"
          >
            <v-text-field
              v-model="form.name"
              :rules="[$rules.required]"
              height="42px"
              type="text"
              outlined
              dense
              label="Document Name"
            ></v-text-field>
            <v-btn
              outlined
              color="primary"
              height="42px"
              @click="submit"
              :loading="btnLoading"
              class="text-capitalize"
              >Add Document</v-btn
            >
          </v-form>
        </div>

        <v-divider></v-divider>

        <DocumentDataTable
          :caseId="uploadDocumentDialog.caseId"
          :headers="documentHeaders"
          ref="dataTable"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mx-4 my-2">
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          :loading="submitBtnLoading"
          @click="submitDocument"
          class="text-capitalize"
        >
          Submit Documents
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import headers from "@/common/caseManagementHeader/caseManagementHeader";
import DocumentDataTable from "@/components/CaseManagement/Document/DataTable";

export default {
  components: {
    DocumentDataTable,
  },

  data() {
    return {
      loading: false,
      btnLoading: false,
      submitBtnLoading: false,
      form: {
        name: "",
      },
      documentHeaders: headers.document_req,
    };
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },

  computed: {
    ...mapGetters({
      uploadDocumentDialog: "dialogs/uploadDocumentDialog",
    }),

    show: {
      get() {
        return this.uploadDocumentDialog.show;
      },
      set(value) {
        this.toggleUploadDocumentDialog({ show: value });
      },
    },
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      toggleUploadDocumentDialog: "dialogs/toggleUploadDocumentDialog",
    }),

    openModal() {},

    modalClosed() {
      this.$refs.form.reset();
      this.toggleUploadDocumentDialog({ show: false });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const onSuccess = () => {
          this.$refs.dataTable.getDocumentList();
          this.$refs.form.reset();
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = new FormData();
        data.append("name", this.form.name);
        data.append("case", this.uploadDocumentDialog.caseId);

        return this.$request(this.$keys.POST, this.$urls.case.document.create, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },

    submitDocument() {
      this.submitBtnLoading = true;

      const onSuccess = () => {
        this.$emit("submitted");
        this.modalClosed();
      };

      const onFinally = () => {
        this.submitBtnLoading = false;
      };

      const params = {
        case_id: this.uploadDocumentDialog.caseId,
      };

      return this.$request(this.$keys.GET, this.$urls.case.document.submit, {
        params,
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>
<style scoped>
.upload-btn {
  border: 1px solid #9e9e9e;
  border-radius: 6px;
  height: 48px;
}
</style>
