export default [
  {
    label: `Open Cases`,
    key: "open",
    value: 0,
  },
  {
    label: `Pending Cases (WIP)`,
    key: "pending",
    value: 0,
  },
  {
    label: `Sent to Exchange`,
    key: "sent_to_eca",
    value: 0,
  },
  {
    label: `Dispatched Case`,
    key: "closed",
    value: 0,
  },
  {
    label: `Rejected Case`,
    key: "rejected",
    value: 0,
  },
];

