const commonTabs = [
  {
    label: `Open Cases`,
    key: "open",
    value: 0,
  },
  {
    label: "Assigned to Sales",
    key: "assigned_to_sales",
    value: 0,
  },
  {
    label: `Quotation Sent`,
    key: "quotation_sent",
    value: 0,
  },
  {
    label: `Payment Confirmation Pending`,
    key: "payment_confirmation",
    value: 0,
  },
  {
    label: `Converted`,
    key: "converted",
    value: 0,
  },
  {
    label: "Assigned to Operation",
    key: "assigned_to_operations",
    value: 0,
  },
];

const payoutTabs = [
  {
    label: `Payout Requested`,
    key: "payout_pending",
    value: 0,
  },
  {
    label: `Payout Rejected`,
    key: "payout_rejected",
    value: 0,
  },
  {
    label: `Payout Done`,
    key: "payout_done",
    value: 0,
  },
];

const accountClearanceTabs = [
  {
    label: `Account Clearance Initiated`,
    key: "account_clearance_pending",
    value: 0,
  },
  {
    label: `Account Clearance Rejected`,
    key: "account_clearance_rejected",
    value: 0,
  },
  {
    label: `Account Clearance Done`,
    key: "account_clearance_done",
    value: 0,
  },
];

export default {
  educational_documentation: [
    ...commonTabs,
    {
      label: `Documents Pending`,
      key: "document_pending",
      value: 0,
    },
    {
      label: `Documents Received`,
      key: "document_received",
      value: 0,
    },
    {
      label: `Documents Verified`,
      key: "document_verified",
      value: 0,
    },
    {
      label: `Assigned to FE/IR`,
      key: "assigned_to_fe_ir",
      value: 0,
    },
    {
      label: `Assigned to University`,
      key: "assigned_to_university",
      value: 0,
    },
    ...payoutTabs,
    ...accountClearanceTabs,
    {
      label: `Closed Cases`,
      key: "closed",
      value: 0,
    },
  ],

  electronic_transcripts: [
    ...commonTabs,
    {
      label: `Documents Pending`,
      key: "document_pending",
      value: 0,
    },
    {
      label: `Documents Uploaded`,
      key: "document_received",
      value: 0,
    },
    ...accountClearanceTabs,
    {
      label: `Sent to ECA`,
      key: "closed",
      value: 0,
    },
  ],

  apostille_translations: [
    ...commonTabs,
    {
      label: `Documents Pending`,
      key: "document_pending",
      value: 0,
    },
    {
      label: `Documents Received`,
      key: "document_received",
      value: 0,
    },
    {
      label: `Assigned to Vendor`,
      key: "assigned_to_vendor",
      value: 0,
    },
    ...payoutTabs,
    ...accountClearanceTabs,
    {
      label: `Closed Cases`,
      key: "closed",
      value: 0,
    },
  ],

  certifications: [
    ...commonTabs,
    {
      label: `Documents Pending`,
      key: "document_pending",
      value: 0,
    },
    {
      label: `Documents Received`,
      key: "document_received",
      value: 0,
    },
    {
      label: `Assigned to Vendor`,
      key: "assigned_to_vendor",
      value: 0,
    },
    ...payoutTabs,
    ...accountClearanceTabs,
    {
      label: `Closed Cases`,
      key: "closed",
      value: 0,
    },
  ],
};
