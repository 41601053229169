const commonStartHeaders = [
  {
    sortable: false,
    text: "Case id",
    value: "case_id",
    width: 120,
  },
  {
    sortable: false,
    text: "Date",
    value: "date",
    width: 120,
  },
];

const commonCenterHeaders = [
  {
    sortable: false,
    text: "Client",
    value: "client",
  },
  {
    sortable: false,
    text: "Turnaround Time (TAT)",
    value: "tat",
    width: 222,
  },
  {
    sortable: false,
    text: "Service",
    value: "service",
  },
  {
    sortable: false,
    text: "Quotation Amount",
    value: "total_amount",
  },
];

const commonEndHeaders = [
  {
    sortable: false,
    text: "Status",
    width: 200,
    value: "status",
  },
  {
    sortable: false,
    text: "Action",
    value: "action",
  },
];

const salesOperations = [
  {
    sortable: false,
    text: "Sales Team",
    width: 200,
    value: "assigned_sales",
  },
  {
    sortable: false,
    text: "Operations Team",
    width: 200,
    value: "assigned_operations",
  },
];

const fe_ir = [
  {
    sortable: false,
    text: "FE/IR",
    width: 200,
    value: "assigned_fe_ir",
  },
];

const vendor = [
  {
    sortable: false,
    text: "Vendor",
    width: 200,
    value: "assigned_vendor",
  },
];

const payoutRejected = [
  {
    text: "Reject Reason",
    value: "payout_reject_reason",
    sortable: false,
    width: 200,
  },
];

const accountClearanceRejected = [
  {
    text: "Reject Reason",
    value: "account_clearance_reject_reason",
    sortable: false,
    width: 200,
  },
];

const closedReason = [
  {
    text: "Closure Reason",
    value: "close_reason",
    sortable: false,
    width: 200,
  },
];

const universityECA = [
  {
    text: "University",
    value: "university",
    sortable: false,
    width: 190,
  },
  {
    text: "ECA Body",
    value: "eca",
    sortable: false,
    width: 200,
  },
  {
    text: "ECA Reference No.",
    value: "eca_reference_number",
    sortable: false,
    width: 152,
  },
];

export default {
  educational_documentation: {
    open: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_sales: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    quotation_sent: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    payment_confirmation: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    converted: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_operations: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_received: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_verified: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_fe_ir: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...fe_ir,
      ...commonEndHeaders,
    ],
    assigned_to_university: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    payout_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    payout_rejected: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...payoutRejected,
      ...commonEndHeaders,
    ],
    payout_done: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...[
        {
          text: "Payout Date",
          value: "status_datetime",
          sortable: false,
          width: 200,
        },
      ],
      ...commonEndHeaders,
    ],
    account_clearance_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    account_clearance_rejected: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...accountClearanceRejected,
      ...commonEndHeaders,
    ],
    account_clearance_done: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    closed: [
      ...commonStartHeaders,
      ...[
        {
          text: "Closure Date",
          value: "status_datetime",
          sortable: false,
          width: 200,
        },
      ],
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...closedReason,
      ...commonEndHeaders,
    ],
  },
  electronic_transcripts: {
    open: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_sales: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    quotation_sent: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    payment_confirmation: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    converted: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_operations: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_received: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    account_clearance_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    account_clearance_rejected: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...accountClearanceRejected,
      ...commonEndHeaders,
    ],
    account_clearance_done: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    closed: [
      ...commonStartHeaders,
      ...[
        {
          text: "Closure Date",
          value: "status_datetime",
          sortable: false,
          width: 200,
        },
      ],
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...closedReason,
      ...commonEndHeaders,
    ],
  },
  apostille_translations: {
    open: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_sales: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    quotation_sent: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    payment_confirmation: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    converted: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_operations: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_received: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_vendor: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...vendor,
      ...commonEndHeaders,
    ],
    payout_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    payout_rejected: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...payoutRejected,
      ...commonEndHeaders,
    ],
    payout_done: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...[
        {
          text: "Payout Date",
          value: "status_datetime",
          sortable: false,
          width: 200,
        },
      ],
      ...commonEndHeaders,
    ],
    account_clearance_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    account_clearance_rejected: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...accountClearanceRejected,
      ...commonEndHeaders,
    ],
    account_clearance_done: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    closed: [
      ...commonStartHeaders,
      ...[
        {
          text: "Closure Date",
          value: "status_datetime",
          sortable: false,
          width: 200,
        },
      ],
      ...commonCenterHeaders,
      ...salesOperations,
      ...closedReason,
      ...commonEndHeaders,
    ],
  },
  certifications: {
    open: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_sales: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    quotation_sent: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    payment_confirmation: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    converted: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...universityECA,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_operations: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    document_received: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    assigned_to_vendor: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...vendor,
      ...commonEndHeaders,
    ],
    payout_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    payout_rejected: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...payoutRejected,
      ...commonEndHeaders,
    ],
    payout_done: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...[
        {
          text: "Payout Date",
          value: "status_datetime",
          sortable: false,
          width: 200,
        },
      ],
      ...commonEndHeaders,
    ],
    account_clearance_pending: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    account_clearance_rejected: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...accountClearanceRejected,
      ...commonEndHeaders,
    ],
    account_clearance_done: [
      ...commonStartHeaders,
      ...commonCenterHeaders,
      ...salesOperations,
      ...commonEndHeaders,
    ],
    closed: [
      ...commonStartHeaders,
      ...[
        {
          text: "Closure Date",
          value: "status_datetime",
          sortable: false,
          width: 200,
        },
      ],
      ...commonCenterHeaders,
      ...salesOperations,
      ...closedReason,
      ...commonEndHeaders,
    ],
  },
};
