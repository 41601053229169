var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3 rounded-t-0",attrs:{"items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},on:{"dblclick:row":_vm.onItemDoubleClick,"contextmenu:row":_vm.onRightClick},scopedSlots:_vm._u([{key:"item.case_id",fn:function(ref){
var item = ref.item;
return [(
          [
            _vm.$keys.WEB,
            _vm.$keys.ANDROID,
            _vm.$keys.IOS,
            _vm.$keys.WHATSAPP,
            _vm.$keys.CALL ].includes(item.source)
        )?_c('div',{staticClass:"mb-1"},[(item.source === _vm.$keys.WEB)?_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v(" mdi-web ")]):_vm._e(),(item.source === _vm.$keys.ANDROID)?_c('v-icon',{attrs:{"size":"20","color":"green"}},[_vm._v(" mdi-android ")]):_vm._e(),(item.source === _vm.$keys.IOS)?_c('v-icon',{attrs:{"size":"20","color":"black"}},[_vm._v(" mdi-apple ")]):_vm._e(),(item.source === _vm.$keys.WHATSAPP)?_c('v-icon',{attrs:{"size":"20","color":"green"}},[_vm._v(" mdi-whatsapp ")]):_vm._e(),(item.source === _vm.$keys.CALL)?_c('v-icon',{attrs:{"size":"20","color":"black"}},[_vm._v(" mdi-phone ")]):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(item.case_id)+" "),(item.delayed_days > 0)?_c('v-chip',{staticClass:"mt-1",attrs:{"small":"","pill":"","color":"red","outlined":""}},[_vm._v(" Delayed by "+_vm._s(item.delayed_days)+" days ")]):_vm._e()]}},{key:"item.date",fn:function(ref){
        var item = ref.item;
return [(item.converted_dt)?_c('div',[_vm._v(" "+_vm._s(_vm.$utils.getDateFormat(item.converted_dt))+" ")]):_c('div',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.status_datetime",fn:function(ref){
        var item = ref.item;
return [(item.status_datetime)?_c('div',[_vm._v(" "+_vm._s(_vm.$utils.getDateFormat(item.status_datetime))+" ")]):_c('div',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.client",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.client.name))]),_c('div',[_vm._v(_vm._s(item.client.user.email))]),_c('div',[_vm._v(_vm._s(item.client.user.mobile_display))])]}},{key:"item.tat",fn:function(ref){
        var item = ref.item;
return [(item.tat_status === 'not_started')?_c('div',[_vm._v(" TAT not started "),_c('div',{staticClass:"red--text"},[_vm._v(_vm._s(item.tat_reason))])]):(item.tat_status === 'stopped')?_c('div',[_vm._v(" TAT stopped "),_c('div',{staticClass:"red--text"},[_vm._v(_vm._s(item.tat_reason))])]):_c('div',[_c('div',[_c('span',{staticClass:"text-body-2"},[_vm._v("Client TAT: ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.client_tat_days)+" "+_vm._s(item.client_tat_days > 1 ? " days" : " day")+" ")])]),_c('div',{},[_c('span',{staticClass:"text-body-2"},[_vm._v("Internal TAT: ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.internal_tat_days)+" "+_vm._s(item.internal_tat_days > 1 ? " days" : " day")+" ")])])])]}},{key:"item.total_amount",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"py-4 d-flex align-center justify-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("₹"+_vm._s(item.total_amount))]),(item.total_amount)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer"},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('div',[(item.total_university_cost)?_c('div',[_vm._v(" University Cost: ₹"+_vm._s(item.total_university_cost)+" ")]):_vm._e(),_c('div',[_vm._v("Processing Cost: ₹"+_vm._s(item.total_processing_cost))]),_c('div',[_vm._v("Shipping Cost: ₹"+_vm._s(item.shipping_cost))]),_c('div',[_vm._v("GST Amount: ₹"+_vm._s(item.gst_amount))]),_c('div',[_vm._v("Discount Amount: ₹"+_vm._s(item.discount_amount))])])]):_vm._e()],1)]}},{key:"item.university",fn:function(ref){
        var item = ref.item;
return [(item.university)?_c('div',[_vm._v(" "+_vm._s(item.university.name)+" "),(item.university.city)?_c('div',[_vm._v(" "+_vm._s(item.university.city.name)),(item.university.city.state)?_c('span',[_vm._v(", "+_vm._s(item.university.city.state.name))]):_vm._e()]):_vm._e(),(item.actions.includes(_vm.$keys.ASSIGN_UNIVERSITY))?_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.assignUniversity(item.id)}}},[_c('span',{staticClass:"executive"},[_vm._v("Assign University")])]):_vm._e(),(item.actions.includes(_vm.$keys.UNASSIGN_UNIVERSITY))?_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.unassignUniversity(item.id)}}},[_c('span',{staticClass:"executive"},[_vm._v("Unassign University")])]):_vm._e()]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.eca",fn:function(ref){
        var item = ref.item;
return [(item.eca.length)?_c('div',{staticClass:"text-left"},_vm._l((item.eca),function(eca){return _c('li',{key:("eca-" + (eca.id))},[_vm._v(" "+_vm._s(eca.name)+" ")])}),0):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.eca_reference_number",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_vm._v(" "+_vm._s(item.eca_reference_number)+" "),_c('div',{staticStyle:{"max-width":"100px"}},[_c('v-edit-dialog',{attrs:{"large":"","transition":"slide-y-transition"},on:{"save":function($event){return _vm.onSave('eca_reference_number', item)},"open":function($event){_vm.editDialog.eca_reference_number = item.eca_reference_number},"close":function($event){_vm.editDialog.eca_reference_number = ''}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"my-4"},[_vm._v("ECA Reference Number")]),_c('v-text-field',{attrs:{"label":"Enter ECA Reference Number","type":"text","outlined":"","dense":""},model:{value:(_vm.editDialog.eca_reference_number),callback:function ($$v) {_vm.$set(_vm.editDialog, "eca_reference_number", $$v)},expression:"editDialog.eca_reference_number"}})]},proxy:true}],null,true)},[_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","x-small":"","color":"primary"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil-outline")])],1)],1)],1)])]}},{key:"item.documents",fn:function(ref){
        var item = ref.item;
return [(item.required_documents.length)?_c('div',_vm._l((item.required_documents),function(list,index){return _c('div',{key:index},[_c('div',[_vm._v(" "+_vm._s(list.title)+" - "),(list.status == 'pending')?_c('span',{staticClass:"blue--text bold"},[_vm._v(" "+_vm._s(list.status_display))]):_vm._e(),(list.status == 'uploaded')?_c('span',{staticClass:"orange--text bold"},[_vm._v(" "+_vm._s(list.status_display))]):_vm._e(),(list.status == 'verified')?_c('span',{staticClass:"green--text bold"},[_vm._v(" "+_vm._s(list.status_display))]):_vm._e(),(list.status == 'rejected')?_c('span',{staticClass:"red--text bold"},[_vm._v(" "+_vm._s(list.status_display))]):_vm._e(),(list.status == 'downloaded')?_c('span',{staticClass:"gray--text bold"},[_vm._v(" "+_vm._s(list.status_display))]):_vm._e(),(list.status == 'removed')?_c('span',{staticClass:"red--text bold"},[_vm._v(" "+_vm._s(list.status_display))]):_vm._e()])])}),0):_c('span',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.service",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.service.name)+" ")]}},{key:"item.follow_up_date",fn:function(ref){
        var item = ref.item;
return [(item.follow_up)?_c('div',[_vm._v(" "+_vm._s(_vm.$utils.getDateFormat(item.follow_up.follow_up_date))+" ")]):_c('div',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.follow_up_reason",fn:function(ref){
        var item = ref.item;
return [(item.follow_up)?_c('div',[_vm._v(" "+_vm._s(item.follow_up.reason)+" ")]):_c('div',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.close_reason",fn:function(ref){
        var item = ref.item;
return [(item.close_reason)?_c('div',[_vm._v(" "+_vm._s(item.close_reason)+" ")]):_c('div',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.on_hold_reason",fn:function(ref){
        var item = ref.item;
return [(item.follow_up)?_c('div',[_vm._v(" "+_vm._s(item.follow_up.reason)+" ")]):_c('div',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.status === 'closed')?_c('div',[_c('div',{staticClass:"pointer primary--text executive",on:{"click":function($event){return _vm.openEcaStatusHistoryDialog(item)}}},[_vm._v(" View Status History ")])]):(item.status)?_c('div',[_c('v-chip',{attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(item.status_display)+" ")])],1):_c('div',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.assigned_sales",fn:function(ref){
        var item = ref.item;
return [(item.sales)?_c('div',[_vm._v(" "+_vm._s(item.sales.name)+" ")]):_vm._e(),(item.actions.includes(_vm.$keys.ASSIGN_SALES))?_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.assignSales(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-plus ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Assign Sales ")])],1):_vm._e(),(item.actions.includes(_vm.$keys.CHANGE_SALES))?_c('div',{staticClass:"pointer d-flex justify-center align-end primary--text mb-1",on:{"click":function($event){return _vm.assignSales(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-edit ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Change Sales ")])],1):_vm._e(),(item.actions.includes(_vm.$keys.UNASSIGN_ME))?_c('div',{staticClass:"pointer d-flex justify-center align-end primary--text",on:{"click":function($event){return _vm.unAssignSales(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-minus ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Unassign me")])],1):_vm._e(),(item.actions.includes(_vm.$keys.UNASSIGN_SALES))?_c('div',{staticClass:"pointer d-flex justify-center align-end primary--text",on:{"click":function($event){return _vm.unAssignSales(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-minus ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Unassign Sales")])],1):_vm._e()]}},{key:"item.assigned_operations",fn:function(ref){
        var item = ref.item;
return [(item.operations)?_c('div',[_vm._v(" "+_vm._s(item.operations.name)+" "),(
            item.actions.includes(_vm.$keys.CHANGE_OPERATIONS) ||
            item.actions.includes(_vm.$keys.UNASSIGN_ME) ||
            item.actions.includes(_vm.$keys.UNASSIGN_OPERATIONS)
          )?_c('div',[(item.actions.includes(_vm.$keys.CHANGE_OPERATIONS))?_c('div',{staticClass:"pointer d-flex justify-center primary--text mb-1",on:{"click":function($event){return _vm.assignOperations(
                item.id,
                item.university ? item.university.id : null
              )}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-edit ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Change Operations ")])],1):_vm._e(),(item.actions.includes(_vm.$keys.UNASSIGN_ME))?_c('div',{staticClass:"pointer d-flex justify-center primary--text",on:{"click":function($event){return _vm.unAssignOperations(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-minus ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Unassign me")])],1):_vm._e(),(item.actions.includes(_vm.$keys.UNASSIGN_OPERATIONS))?_c('div',{staticClass:"pointer d-flex justify-center primary--text",on:{"click":function($event){return _vm.unAssignOperations(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-minus ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Unassign Operations")])],1):_vm._e()]):_vm._e()]):_c('div',[(item.actions.includes(_vm.$keys.ASSIGN_OPERATIONS))?_c('div',[(item.actions.includes(_vm.$keys.ASSIGN_OPERATIONS))?_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.assignOperations(
                item.id,
                item.university ? item.university.id : null
              )}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-plus ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Assign Operations ")])],1):_vm._e()]):_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.assigned_fe_ir",fn:function(ref){
              var item = ref.item;
return [(item.fe_ir)?_c('div',[_vm._v(" "+_vm._s(item.fe_ir.name)),_c('br'),(item.actions.includes(_vm.$keys.CHANGE_FE_IR))?_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.assignFeIr(item.id, item.university.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-edit ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Change FE/IR ")])],1):_vm._e(),(item.actions.includes(_vm.$keys.UNASSIGN_FE_IR))?_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.unAssignFeIr(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-minus ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Unassign FE/IR ")])],1):_vm._e()]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.assigned_vendor",fn:function(ref){
              var item = ref.item;
return [(item.vendor)?_c('div',[_vm._v(" "+_vm._s(item.vendor.name)),_c('br'),_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.assignVendor(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-edit ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Change Vendor ")])],1),_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.unAssignVendor(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-account-minus ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Unassign Vendor ")])],1)]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},[_vm._l((_vm.btnActions(item)),function(action){return _c('v-btn',{key:action.key,staticClass:"text-capitalize",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.actionHandler(action.key, item)}}},[(action.icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(action.icon))]):_vm._e(),_vm._v(" "+_vm._s(action.text)+" ")],1)}),(_vm.menuActions(item).length)?_c('v-menu',{attrs:{"left":"","offset-y":"","min-width":"200","width":"200","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var attrs = ref.attrs;
              var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"small":"","outlined":"","color":"primary","width":"5px","min-width":"5px"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"nav":"","dense":"","outlined":_vm.$vuetify.theme.dark}},_vm._l((_vm.menuActions(item)),function(action){return _c('v-list-item',{key:action.key,attrs:{"link":""},on:{"click":function($event){return _vm.actionHandler(action.key, item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(action.text))])],1)}),1)],1):_vm._e()],2)]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),(
      _vm.rightClickMenu.selectedItem &&
      _vm.actions(_vm.rightClickMenu.selectedItem).length
    )?_c('v-menu',{attrs:{"position-x":_vm.rightClickMenu.positionX,"position-y":_vm.rightClickMenu.positionY,"transition":"scale-transition","min-width":"200","width":"200","absolute":"","offset-y":""},model:{value:(_vm.rightClickMenu.show),callback:function ($$v) {_vm.$set(_vm.rightClickMenu, "show", $$v)},expression:"rightClickMenu.show"}},[_c('v-list',{attrs:{"nav":"","dense":"","outlined":_vm.$vuetify.theme.dark}},_vm._l((_vm.actions(_vm.rightClickMenu.selectedItem)),function(action){return _c('v-list-item',{key:action.key,attrs:{"link":""},on:{"click":function($event){return _vm.actionHandler(action.key, _vm.rightClickMenu.selectedItem)}}},[_c('v-list-item-title',[_vm._v(_vm._s(action.text))])],1)}),1)],1):_vm._e(),_c('CaseCommonDialog',{on:{"submitted":_vm.reloadData}}),_c('UploadDocumentDialog',{on:{"submitted":_vm.reloadData}}),_c('ConfirmationDialog',{attrs:{"height":"130px","width":"130px"},on:{"confirmed":_vm.onConfirmed},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [(_vm.confirmation_type === 'document_received')?_c('span',[_vm._v(" Do you really want to mark as Document Received. ")]):_vm._e(),(_vm.confirmation_type === 'initiate_account_clearance')?_c('span',[_vm._v(" Do you really want to Initiate Account Clearance. ")]):_vm._e(),(_vm.confirmation_type === 'close_case')?_c('span',[_vm._v(" Do you really want to Close this Case. ")]):_vm._e()]},proxy:true},{key:"confirmBtnText",fn:function(){return [(_vm.confirmation_type === 'document_received')?_c('span',[_vm._v(" Yes, Mark ")]):_vm._e(),(_vm.confirmation_type === 'initiate_account_clearance')?_c('span',[_vm._v(" Yes, Initiate ")]):_vm._e(),(_vm.confirmation_type === 'close_case')?_c('span',[_vm._v(" Yes, Close ")]):_vm._e()]},proxy:true}])}),_c('RequestPayoutDialog',{on:{"submitted":_vm.reloadData}}),_c('AddTrackingInfo',{on:{"submitted":_vm.reloadData}}),_c('select-executive-modal',{on:{"selected":_vm.onSelected}}),_c('eca-status-history-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }