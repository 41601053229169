var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3 rounded-t-0",attrs:{"items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.getDateFormat(item.modified))+" ")]}},{key:"item.initiated_by",fn:function(ref){
var item = ref.item;
return [(item.created_by)?_c('div',[_vm._v(" "+_vm._s(item.created_by)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.name)+" ")]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [(item.client.user.email || item.client.user.mobile)?_c('div',[(item.client.user.mobile)?_c('div',[_vm._v(" "+_vm._s(item.client.user.mobile_display)+" ")]):_vm._e(),(item.client.user.email)?_c('div',[_vm._v(" "+_vm._s(item.client.user.email)+" ")]):_vm._e()]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [(item.service)?_c('div',[_vm._v(" "+_vm._s(item.service.name)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.eca_body",fn:function(ref){
var item = ref.item;
return [(item.eca.length)?_c('div',{staticClass:"text-left"},_vm._l((item.eca),function(eca){return _c('li',{key:("eca-" + (eca.id))},[_vm._v(" "+_vm._s(eca.name)+" ")])}),0):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.eca_reference_number",fn:function(ref){
var item = ref.item;
return [(item.eca_reference_number)?_c('div',[_vm._v(" "+_vm._s(item.eca_reference_number)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [(item.documents.length)?_c('div',[_vm._l((item.documents),function(document){return _c('div',{key:("doc-" + (document.id))},[_vm._v(" "+_vm._s(document.name)+" ")])}),_c('div',{staticClass:"mt-1 d-flex justify-center",staticStyle:{"gap":"6px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.viewDocuments(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Documents")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.downloadDocuments(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Documents")])])],1)],2):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.university_status)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.university_status.bg_color,"text-color":item.university_status.text_color}},[_vm._v(" "+_vm._s(item.university_status.text)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},_vm._l((_vm.btnActions(item)),function(action){return _c('v-btn',{key:action.key,staticClass:"text-capitalize",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.actionHandler(action.key, item)}}},[(action.icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(action.icon))]):_vm._e(),_vm._v(" "+_vm._s(action.text)+" ")],1)}),1)]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),_c('AddTrackingInfo',{on:{"submitted":_vm.reloadData}}),_c('UploadDocumentDialog',{on:{"submitted":_vm.reloadData}}),_c('document-viewer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }