export default {
  open_case: [
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Date",
      value: "date",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      width: 150,
    },
    {
      sortable: false,
      text: "Contacts",
      value: "contacts",
    },
    {
      sortable: false,
      text: "Service",
      value: "service",
    },
    {
      sortable: false,
      text: "Quotation Amount",
      value: "total",
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Sales Team",
      width: 200,
      value: "assigned_sales",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  assigned: [
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Date",
      value: "date",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      width: 150,
      value: "client",
    },
    {
      sortable: false,
      text: "Contacts",
      value: "contacts",
    },
    {
      sortable: false,
      text: "Service",
      value: "service",
    },
    {
      sortable: false,
      text: "Quotation Amount",
      value: "total",
    },
    {
      sortable: false,
      text: "Sales Team",
      width: 200,
      value: "assigned_sales",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  follow_up: [
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Date",
      value: "date",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      width: 150,
      value: "client",
    },
    {
      sortable: false,
      text: "Contacts",
      value: "contacts",
    },
    {
      sortable: false,
      text: "Service",
      value: "service",
    },
    {
      sortable: false,
      text: "Quotation Amount",
      value: "total",
    },
    {
      sortable: false,
      text: "Sales Team",
      width: 200,
      value: "assigned_sales",
    },
    {
      sortable: false,
      text: "Follow-up Date",
      width: 130,
      value: "follow_up_reason_date",
    },
    {
      sortable: false,
      width: 140,
      text: "Follow-up Reason",
      value: "follow_up_reason",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  payment_confirmation: [
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Date",
      value: "date",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      width: 150,
      value: "client",
    },
    {
      sortable: false,
      text: "Contacts",
      value: "contacts",
    },
    {
      sortable: false,
      text: "Service",
      value: "service",
    },
    {
      sortable: false,
      text: "Quotation Amount",
      value: "total",
    },
    {
      sortable: false,
      text: "Sales Team",
      width: 200,
      value: "assigned_sales",
    },
    {
      sortable: false,
      text: "Status",
      value: "payment_status",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  converted: [
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Date",
      value: "date",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      width: 150,
      value: "client",
    },
    {
      sortable: false,
      text: "Contacts",
      value: "contacts",
    },
    {
      sortable: false,
      text: "Service",
      value: "service",
    },
    {
      sortable: false,
      text: "Quotation Amount",
      value: "total",
    },
    {
      sortable: false,
      text: "Sales Team",
      width: 150,
      value: "assigned_sales",
    },
    {
      sortable: false,
      text: "Assigned Operations",
      width: 150,
      value: "assigned_operations",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  irrelevant: [
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Date",
      value: "date",
      width: 120,
    },
    {
      sortable: false,
      width: 150,
      text: "Client",
      value: "client",
    },
    {
      sortable: false,
      text: "Contacts",
      width: 200,
      value: "contacts",
    },
    {
      sortable: false,
      text: "Service",
      value: "service",
    },
    {
      sortable: false,
      text: "Quotation Amount",
      value: "total",
    },
    {
      sortable: false,
      text: "Sales Team",
      width: 200,
      value: "assigned_sales",
    },
    {
      sortable: false,
      text: "Reason of Irrelevance",
      width: 170,
      value: "irrelevance_reason",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  quotation_send: [
    {
      sortable: false,
      text: "Date",
      value: "created",
    },
    {
      sortable: false,
      text: "Send By",
      value: "sent_by",
    },
    {
      sortable: false,
      text: "Quotation Amount",
      value: "amount",
    },
    {
      sortable: false,
      text: "Type",
      value: "type_display",
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
  ],

  payment_history: [
    {
      sortable: false,
      text: "Payment Date",
      value: "payment_date",
    },
    {
      sortable: false,
      text: "Payment Mode",
      value: "mode",
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remarks",
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  payout_history: [
    {
      sortable: false,
      text: "Date",
      value: "created",
    },
    {
      sortable: false,
      text: "Payout for",
      value: "payout_for_display",
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remarks",
      width:'400px'
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  refund_history: [
    {
      sortable: false,
      text: "Date",
      value: "date",
    },
    {
      sortable: false,
      text: "Clause",
      value: "clause",
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remarks",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  document_req: [
    {
      sortable: false,
      text: "Document Name",
      value: "name",
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],
};
