<template>
  <v-data-table
    :loading="loading"
    class="rounded-xl"
    loading-text="Please wait ....Loading"
    :headers="headers"
    :items="items"
    hide-default-footer
    mobile-breakpoint
  >
    <template v-slot:[`item.status`]="{ item }">
      <div class="py-3">
        <v-chip
          small
          class="lighten-5 text--darken-2 font-weight-bold text-capitalize"
          :class="
            item.status == 'verified'
              ? 'success success--text'
              : item.status == 'pending'
              ? 'blue blue--text'
              : 'error error--text'
          "
        >
          {{ item.status_display }}
        </v-chip>
      </div>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <div class="d-flex align-center justify-center" style="gap: 10px">
        <div v-for="action in item.actions" :key="action.key">
          <v-tooltip bottom v-if="action.show">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="action.disabled"
                @click="actionHandler(action.key, item)"
              >
                <v-icon> mdi-{{ action.icon }} </v-icon>
              </v-btn>
            </template>
            {{ action.text }}
          </v-tooltip>
        </div>
        <input
          @change="uploadFile(item)"
          v-show="false"
          type="file"
          :ref="`upload_${item.id}`"
          accept="image/*, application/pdf"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      items: [],
    };
  },

  props: {
    caseId: Number,
    headers: Array,
  },

  computed: {
    ...mapGetters({
      getUserDetail: "getUser",
    }),
  },

  mounted() {
    this.getDocumentList();
  },

  methods: {
    getDocumentList() {
      this.loading = true;

      const onSuccess = (res) => {
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        case_id: this.caseId,
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.case.document.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    actionHandler(action, item) {
      switch (action) {
        case "download":
          window.open(item.file, "_blank");
          break;

        case "upload":
          this.$refs[`upload_${item.id}`].click();
          break;

        case "approve":
          this.approve(item.id);
          break;

        case "reject":
          this.reject(item.id);
          break;

        case "delete":
          this.delete(item.id);
          break;
      }
    },

    approve(id) {
      const onSuccess = () => {
        this.getDocumentList();
      };

      const params = {
        document_id: id,
      };

      const data = new FormData();
      data.append("status", this.$keys.VERIFIED);

      return this.$request(this.$keys.PATCH, this.$urls.case.document.update, {
        params,
        data,
        onSuccess,
      });
    },

    reject(id) {
      const onSuccess = () => {
        this.getDocumentList();
      };

      const params = {
        document_id: id,
      };

      const data = new FormData();
      data.append("status", this.$keys.REJECTED);

      return this.$request(this.$keys.PATCH, this.$urls.case.document.update, {
        params,
        data,
        onSuccess,
      });
    },

    delete(id) {
      const onSuccess = () => {
        this.getDocumentList();
      };

      const params = {
        document_id: id,
      };

      return this.$request(this.$keys.DELETE, this.$urls.case.document.delete, {
        params,
        onSuccess,
      });
    },

    uploadFile(item) {
      this.loading = true;

      const input = this.$refs[`upload_${item.id}`];
      const file = input.files[0];

      const onSuccess = () => {
        this.getDocumentList();
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        document_id: item.id,
      };

      const data = new FormData();
      data.append("file", file);

      if (item.status === 'pending') {
        data.append("status", this.$keys.UPLOADED);
      }

      return this.$request(this.$keys.PATCH, this.$urls.case.document.update, {
        params,
        data,
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>