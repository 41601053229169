<template>
  <div>
    <v-data-table
      class="elevation-3 rounded-t-0"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ $utils.getDateFormat(item.modified) }}
      </template>

      <template v-slot:[`item.initiated_by`]="{ item }">
        <div v-if="item.created_by">
          {{ item.created_by }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.client`]="{ item }">
        {{ item.client.name }}
      </template>

      <template v-slot:[`item.contacts`]="{ item }">
        <div v-if="item.client.user.email || item.client.user.mobile">
          <div v-if="item.client.user.mobile">
            {{ item.client.user.mobile_display }}
          </div>
          <div v-if="item.client.user.email">
            {{ item.client.user.email }}
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.service`]="{ item }">
        <div v-if="item.service">
          {{ item.service.name }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.eca_body`]="{ item }">
        <div v-if="item.eca.length" class="text-left">
          <li v-for="eca in item.eca" :key="`eca-${eca.id}`">
            {{ eca.name }}
          </li>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.eca_reference_number`]="{ item }">
        <div v-if="item.eca_reference_number">
          {{ item.eca_reference_number }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.documents`]="{ item }">
        <div v-if="item.documents.length">
          <div v-for="document in item.documents" :key="`doc-${document.id}`">
            {{ document.name }}
          </div>
          <div class="mt-1 d-flex justify-center" style="gap: 6px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  icon
                  @click="viewDocuments(item)"
                >
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>View Documents</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  icon
                  @click="downloadDocuments(item)"
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download Documents</span>
            </v-tooltip>
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          class="font-weight-bold"
          :color="item.university_status.bg_color"
          :text-color="item.university_status.text_color"
          v-if="item.university_status"
        >
          {{ item.university_status.text }}
        </v-chip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize"
            v-for="action in btnActions(item)"
            :key="action.key"
            @click="actionHandler(action.key, item)"
          >
            <v-icon left v-if="action.icon">mdi-{{ action.icon }}</v-icon>
            {{ action.text }}
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <!-- add tracking info dialog -->
    <AddTrackingInfo @submitted="reloadData" />

    <!-- upload documents dialog -->
    <UploadDocumentDialog @submitted="reloadData" />

    <document-viewer />
  </div>
</template>

<script>
import _ from "lodash";
import { saveAs } from "file-saver";
import { mapActions, mapGetters } from "vuex";
import { universityHeaders } from "@/headers/caseManagement";

import DocumentViewer from "@/components/Dialogs/DocumentViewer";
import AddTrackingInfo from "@/components/CaseManagement/AddTrackingInfo";
import UploadDocumentDialog from "@/components/Dialogs/UploadDocumentDialog";

export default {
  components: { DocumentViewer, AddTrackingInfo, UploadDocumentDialog },
  data() {
    return {
      loading: false,
      items: [],

      page_total: 1,
      page_number: 1,
      total_count: 0,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_count: 10,

      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters({
      search: "caseManagement/search",
      filters: "caseManagement/filters",
      activeTab: "caseManagement/activeTab",
    }),

    headers() {
      return universityHeaders[this.activeTab.key];
    },

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },
  watch: {
    page_count() {
      this.getCaseList();
    },

    page_number() {
      this.getCaseList();
      this.updateRoute();
    },

    activeTab: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },

    filters: {
      handler() {
        this.reloadData();
        this.updateRoute();
      },
      deep: true,
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.setSearch(String(query.search));
    }

    if (query.date) {
      this.setFilters({ date: [String(query.date)] });
    }

    if (query.start_date && query.end_date) {
      this.setFilters({
        date: [String(query.start_date), String(query.end_date)],
      });
    }

    this.updateRoute();
  },

  mounted() {
    this.getCaseList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      setSearch: "caseManagement/setSearch",
      setFilters: "caseManagement/setFilters",
      toggleDocumentViewer: "dialogs/toggleDocumentViewer",
      toggleAddTrackingInfoDialog: "dialogs/toggleAddTrackingInfoDialog",
      toggleUploadDocumentDialog: "dialogs/toggleUploadDocumentDialog",
    }),

    debouncedReloadData: _.debounce(function () {
      this.reloadData();
      this.updateRoute();
    }, 1000),

    reloadData() {
      this.$emit("reloadData");
      this.getCaseList();
    },

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.filters.date.length) {
        if (this.filters.date.length === 2) {
          query.start_date = String(this.filters.date[0]);
          query.end_date = String(this.filters.date[1]);
        } else {
          query.date = String(this.filters.date[0]);
        }
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    btnActions(item) {
      return this.$_.filter(item.btn_actions, { show_in_menu: false });
    },

    actionHandler(key, item) {
      switch (key) {
        case "accept_case": {
          this.acceptCase(item);
          break;
        }

        case "reject_case": {
          this.rejectCase(item);
          break;
        }

        case "make_payment": {
          this.makePayment(item);
          break;
        }

        case "add_tracking_info": {
          this.toggleAddTrackingInfoDialog({ show: true, item: item });
          break;
        }

        case "upload_document": {
          this.toggleUploadDocumentDialog({ show: true, caseId: item.id });
          break;
        }

        default:
          break;
      }
    },

    acceptCase(item) {
      const onSuccess = (res) => {
        console.log(res.data.message);
        this.reloadData();
      };

      const data = {
        case_id: item.id,
      };

      return this.$request(this.$keys.POST, this.$urls.university.case.accept, {
        data,
        onSuccess,
      });
    },

    rejectCase(item) {
      const onSuccess = (res) => {
        console.log(res.data.message);
        this.reloadData();
      };

      const data = {
        case_id: item.id,
      };

      return this.$request(this.$keys.POST, this.$urls.university.case.reject, {
        data,
        onSuccess,
      });
    },

    makePayment(item) {
      const payload = item.payment_request;
      const options = {
        key: payload.api_key_id,
        amount: payload.amount,
        currency: payload.currency,
        name: payload.name,
        description: payload.description,
        image: payload.image,
        order_id: payload.order_id,
        prefill: payload.prefill,
        theme: {
          color: this.$vuetify.theme.dark
            ? this.$vuetify.theme.themes.dark.primary
            : this.$vuetify.theme.themes.light.primary,
        },
        handler: () => {
          this.reloadData();
          this.showSnackbar({
            text: "Payment successful.",
            color: "success",
          });
        },
      };
      // eslint-disable-next-line no-undef
      const razor_pay = new Razorpay(options);
      razor_pay.open();
      razor_pay.on("payment.failed", () => {
        this.showSnackbar({
          text: "Payment failed.",
          color: "error",
        });
      });
    },

    viewDocuments(item) {
      const documents = item.documents.map((document) => {
        return {
          url: document.file,
          name: document.name,
        };
      });

      this.toggleDocumentViewer({
        show: true,
        items: documents,
      });
    },

    downloadDocuments(item) {
      this.showSnackbar({
        text: "Please wait, download is in progress.",
        color: "success",
      });

      const params = {
        case_id: item.id,
      };

      const onSuccess = (res) => {
        let filename = "documents.pdf";
        if (res.headers["content-disposition"])
          [, filename] = res.headers["content-disposition"].split("filename=");
        saveAs(res.data, filename);
      };

      const onFailure = () => {
        this.showSnackbar({
          text: "Something went wrong",
          color: "error",
        });
      };

      return this.$request(this.$keys.GET, this.$urls.case.document.download, {
        params,
        onSuccess,
        onFailure,
        responseType: "blob",
      });
    },

    getCaseList() {
      this.items = [];
      this.loading = true;

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        case_status: this.activeTab.key,
        search: this.search,
      };

      if (this.filters.date) {
        if (this.filters.date.length === 2) {
          params["start_date"] = this.filters.date[0];
          params["end_date"] = this.filters.date[1];
        } else {
          params["date"] = this.filters.date[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },
  },
};
</script>