const commonStartHeaders = [
  {
    sortable: false,
    text: "Case id",
    value: "case_id",
    width: 120,
  },
  {
    sortable: false,
    text: "Date",
    value: "date",
    width: 120,
  },
  {
    sortable: false,
    text: "Client",
    value: "client",
  },
  {
    sortable: false,
    text: "Service",
    value: "service",
  },
  {
    sortable: false,
    text: "Quotation Amount",
    value: "total_amount",
  },
  {
    sortable: false,
    text: "Sales Team",
    width: 200,
    value: "assigned_sales",
  },
  {
    sortable: false,
    text: "Operations Team",
    width: 200,
    value: "assigned_operations",
  },
  {
    sortable: false,
    text: "Status",
    value: "status",
  },
];

const commonEndHeaders = [
  {
    sortable: false,
    text: "Action",
    value: "action",
  },
];

export default {
  account_clearance_pending: [...commonStartHeaders, ...commonEndHeaders],
  account_clearance_rejected: [
    ...commonStartHeaders,
    {
      sortable: false,
      text: "Reject Reason",
      width: 200,
      value: "reject_reason",
    },
    ...commonEndHeaders,
  ],
  account_clearance_done: [...commonStartHeaders, ...commonEndHeaders],
};
