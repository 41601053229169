<template>
  <div>
    <!-- data table -->
    <v-data-table
      class="elevation-3 rounded-t-0"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
      @dblclick:row="onItemDoubleClick"
      @contextmenu:row="onRightClick"
    >
      <template v-slot:[`item.case_id`]="{ item }">
        <div
          class="mb-1"
          v-if="
            [
              $keys.WEB,
              $keys.ANDROID,
              $keys.IOS,
              $keys.WHATSAPP,
              $keys.CALL,
            ].includes(item.source)
          "
        >
          <v-icon size="20" color="primary" v-if="item.source === $keys.WEB">
            mdi-web
          </v-icon>
          <v-icon size="20" color="green" v-if="item.source === $keys.ANDROID">
            mdi-android
          </v-icon>
          <v-icon size="20" color="black" v-if="item.source === $keys.IOS">
            mdi-apple
          </v-icon>
          <v-icon size="20" color="green" v-if="item.source === $keys.WHATSAPP">
            mdi-whatsapp
          </v-icon>
          <v-icon size="20" color="black" v-if="item.source === $keys.CALL">
            mdi-phone
          </v-icon>
        </div>
        {{ item.case_id }}
        <v-chip
          small
          pill
          class="mt-1"
          v-if="item.delayed_days > 0"
          color="red"
          outlined
        >
          Delayed by {{ item.delayed_days }} days
        </v-chip>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <div class="my-4">
          <div v-if="item.modified">
            {{ $utils.getDateFormat(item.modified) }}
          </div>
          <div v-else><v-icon>mdi-minus</v-icon></div>
        </div>
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <div class="justify-contain" v-if="item.client">
          <div>{{ item.client.name }}</div>
          <div>{{ item.client.user.email }}</div>
          <div>{{ item.client.user.mobile_display }}</div>
        </div>
      </template>

      <template v-slot:[`item.service`]="{ item }">
        {{ item.service.name }}
      </template>

      <template v-slot:[`item.total_amount`]="{ item }">
        <div class="py-4 d-flex align-center justify-center">
          <span class="mr-2">₹{{ item.total_amount }}</span>
          <v-tooltip bottom v-if="item.total_amount">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="pointer">
                mdi-information
              </v-icon>
            </template>
            <div>
              <div v-if="item.total_university_cost">
                University Cost: ₹{{ item.total_university_cost }}
              </div>
              <div>Processing Cost: ₹{{ item.total_processing_cost }}</div>
              <div>Shipping Cost: ₹{{ item.shipping_cost }}</div>
              <div>GST Amount: ₹{{ item.gst_amount }}</div>
              <div>Discount Amount: ₹{{ item.discount_amount }}</div>
            </div>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.assigned_sales`]="{ item }">
        <div v-if="item.sales">
          {{ item.sales.name }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.assigned_operations`]="{ item }">
        <div v-if="item.operations">
          {{ item.operations.name }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          class="font-weight-bold"
          :color="item.account_clearance_status.bg_color"
          :text-color="item.account_clearance_status.text_color"
        >
          {{ item.account_clearance_status.text }}
        </v-chip>
      </template>

      <template v-slot:[`item.reject_reason`]="{ item }">
        <div v-if="item.account_clearance_reject_reason">
          {{ item.account_clearance_reject_reason }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize"
            v-for="action in btnActions(item)"
            :key="action.key"
            @click="actionHandler(action.key, item.id)"
          >
            <v-icon left v-if="action.icon">mdi-{{ action.icon }}</v-icon>
            {{ action.text }}
          </v-btn>

          <v-menu
            left
            offset-y
            min-width="200"
            width="200"
            nudge-bottom="10"
            v-if="menuActions(item).length"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                small
                outlined
                color="primary"
                class="text-capitalize"
                width="5px"
                min-width="5px"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list nav dense :outlined="$vuetify.theme.dark">
              <v-list-item
                link
                v-for="action in menuActions(item)"
                :key="action.key"
                @click="actionHandler(action.key, item)"
              >
                <v-list-item-title>{{ action.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- pagination -->
    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <!-- right click menu -->
    <v-menu
      v-model="rightClickMenu.show"
      :position-x="rightClickMenu.positionX"
      :position-y="rightClickMenu.positionY"
      transition="scale-transition"
      min-width="200"
      width="200"
      absolute
      offset-y
      v-if="
        rightClickMenu.selectedItem &&
        actions(rightClickMenu.selectedItem).length
      "
    >
      <v-list nav dense :outlined="$vuetify.theme.dark">
        <v-list-item
          link
          v-for="action in actions(rightClickMenu.selectedItem)"
          :key="action.key"
          @click="actionHandler(action.key, rightClickMenu.selectedItem)"
        >
          <v-list-item-title>{{ action.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- confirmation dialog -->
    <ConfirmationDialog
      @confirmed="confirmed"
      @cancelled="cancelled"
      height="130px"
      width="130px"
    >
      <template v-slot:form v-if="confirmation_type === 'reject'">
        <v-form lazy-validation ref="form" class="mb-2 mx-2">
          <v-select
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            v-model="form.reason"
            :items="reason_list"
            item-text="title"
            item-value="id"
            flat
            outlined
            dense
            label="Select Reason"
            :rules="[$rules.required]"
          ></v-select>

          <v-textarea
            v-if="form.reason === 0"
            flat
            :rows="3"
            outlined
            v-model="form.other_reason"
            label="Type here..."
            :rules="[$rules.required]"
          ></v-textarea>
        </v-form>
      </template>
      <template v-slot:confirmBtnText> Yes {{ confirmation_type }} </template>
    </ConfirmationDialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import { accountsHeaders } from "@/headers/caseManagement";
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export default {
  components: { ConfirmationDialog },

  data() {
    return {
      loading: false,
      items: [],

      page_total: 1,
      page_number: 1,
      total_count: 0,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_count: 10,

      rightClickMenu: {
        show: false,
        positionX: 0,
        positionY: 0,
        selectedItem: null,
      },

      reason_list: [],
      selectedItem: null,
      confirmation_type: null,
      form: {
        reason: null,
        other_reason: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      search: "caseManagement/search",
      filters: "caseManagement/filters",
      activeTab: "caseManagement/activeTab",
    }),

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },

    headers() {
      return accountsHeaders[this.activeTab.key];
    },
  },

  watch: {
    page_count() {
      this.getCaseList();
    },

    page_number() {
      this.getCaseList();
      this.updateRoute();
    },

    activeTab: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },

    filters: {
      handler() {
        this.reloadData();
        this.updateRoute();
      },
      deep: true,
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.setSearch(String(query.search));
    }

    if (query.executive) {
      this.setFilters({ executive: Number(query.executive) });
    }

    if (query.date) {
      this.setFilters({ date: [String(query.date)] });
    }

    if (query.start_date && query.end_date) {
      this.setFilters({
        date: [String(query.start_date), String(query.end_date)],
      });
    }

    this.updateRoute();
  },

  mounted() {
    this.getCaseList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      setSearch: "caseManagement/setSearch",
      setFilters: "caseManagement/setFilters",
      toggleCnfDialog: "dialogs/toggleConfirmationDialog",
      setCnfDialogBtnLoading: "dialogs/setConfirmationDialogBtnLoading",
    }),

    debouncedReloadData: _.debounce(function () {
      this.reloadData();
      this.updateRoute();
    }, 1000),

    reloadData() {
      this.$emit("reloadData");
      this.getCaseList();
    },

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.filters.executive) {
        query.executive = Number(this.filters.executive);
      }

      if (this.filters.date.length) {
        if (this.filters.date.length === 2) {
          query.start_date = String(this.filters.date[0]);
          query.end_date = String(this.filters.date[1]);
        } else {
          query.date = String(this.filters.date[0]);
        }
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    actions(item) {
      return item.btn_actions;
    },

    btnActions(item) {
      return this.$_.filter(item.btn_actions, { show_in_menu: false });
    },

    menuActions(item) {
      return this.$_.filter(item.btn_actions, { show_in_menu: true });
    },

    actionHandler(key, id) {
      switch (key) {
        case "view_case": {
          this.openCaseDetail(id);
          break;
        }

        case "approve_account_clearance": {
          this.openConfirmationDialog(id, "approve");
          break;
        }

        case "reject_account_clearance": {
          this.openConfirmationDialog(id, "reject");
          break;
        }

        default:
          break;
      }
    },

    openConfirmationDialog(id, type) {
      this.selectedItem = id;
      this.confirmation_type = type;
      if (type === "reject") this.getReasonList();
      this.toggleCnfDialog({ show: true, action: type });
    },

    confirmed() {
      if (this.confirmation_type === "approve") this.approve();
      if (this.confirmation_type === "reject") this.reject();
    },

    cancelled() {
      if (this.$refs.form) this.$refs.form.reset();
    },

    approve() {
      const onSuccess = () => {
        this.toggleCnfDialog({ show: false });
        this.reloadData();
      };

      const onFinally = () => {
        this.setCnfDialogBtnLoading({ loading: false });
      };

      const params = {
        case_id: this.selectedItem,
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.case.accountClearance.approve,
        {
          params,
          onSuccess,
          onFinally,
        }
      );
    },

    reject() {
      if (this.$refs.form.validate()) {
        const onSuccess = () => {
          this.toggleCnfDialog({ show: false });
          this.reloadData();
        };

        const onFinally = () => {
          this.setCnfDialogBtnLoading({ loading: false });
        };

        const data = {
          case: this.selectedItem,
        };

        if (this.form.reason) data["reason"] = this.form.reason;
        if (this.form.other_reason)
          data["other_reason"] = this.form.other_reason;

        return this.$request(
          this.$keys.POST,
          this.$urls.case.accountClearance.reject,
          {
            data,
            onSuccess,
            onFinally,
          }
        );
      } else {
        this.setCnfDialogBtnLoading({ loading: false });
      }
    },

    getReasonList() {
      const onSuccess = (res) => {
        this.reason_list = res.data.data;
        this.reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      let params = {};
      params["reason_type"] = "reject";

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },

    getCaseList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.items = res.data.data;
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        case_status: this.activeTab.key,
        search: this.search,
        executive_id: this.filters.executive,
      };

      if (this.filters.date) {
        if (this.filters.date.length === 2) {
          params["start_date"] = this.filters.date[0];
          params["end_date"] = this.filters.date[1];
        } else {
          params["date"] = this.filters.date[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },

    openCaseDetail(id) {
      this.$router.push({
        name: "caseDetail",
        params: {
          id: id,
        },
      });
    },

    onItemDoubleClick(event, { item }) {
      this.openCaseDetail(item.id);
    },

    onRightClick(event, { item }) {
      event.preventDefault();
      this.rightClickMenu.selectedItem = item;
      this.rightClickMenu.show = false;
      this.rightClickMenu.positionX = event.clientX;
      this.rightClickMenu.positionY = event.clientY;
      this.$nextTick(() => {
        this.rightClickMenu.show = true;
      });
    },
  },
};
</script>