const commonHeaders = [
  {
    sortable: false,
    text: "Date",
    value: "date",
    width: 120,
  },
  {
    sortable: false,
    text: "Case Id",
    value: "case_id",
    width: 120,
  },
  {
    sortable: false,
    text: "Initiated by",
    value: "initiated_by",
    width: 120,
  },
  {
    sortable: false,
    text: "Student Name",
    value: "client",
    width: 150,
  },
  {
    sortable: false,
    text: "Contacts",
    value: "contacts",
    width: 150,
  },
  {
    sortable: false,
    text: "Document Type",
    value: "service",
    width: 150,
  },
  {
    sortable: false,
    text: "ECA Body",
    value: "eca_body",
    width: 200,
  },
  {
    text: "ECA Reference No.",
    value: "eca_reference_number",
    sortable: false,
    width: 152,
  },
  {
    sortable: false,
    text: "Documents",
    value: "documents",
    width: 150,
  },
];

const action = [
  {
    sortable: false,
    text: "Action",
    value: "action",
  },
];

export default {
  open: [...commonHeaders, ...action],
  pending: [...commonHeaders, ...action],
  sent_to_eca: [...commonHeaders],
  closed: [...commonHeaders],
  rejected: [...commonHeaders],
};
