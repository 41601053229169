<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="500px"
    content-class="rounded-xl"
  >
    <v-overlay class="justify-center align-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="6"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6" v-if="type === $keys.FOLLOW_UP">
          Please select reason to move <br />
          this case to Follow-up Required
        </div>
        <div class="text-h6" v-if="type === $keys.ON_HOLD">
          Please select reason to move <br />
          this case to On Hold
        </div>
        <div class="text-h6" v-if="type === $keys.CANCELLED">
          Please select reason to close this case.
        </div>
        <div class="text-h6" v-if="type === $keys.IRRELEVANT">
          Please select reason to mark <br />
          this case as Irrelevant
        </div>
        <v-btn icon>
          <v-icon @click="modalClosed" size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="mt-5 d-flex justify-space-between" v-if="case_detail">
          <div class="sub-title text-start">
            {{ case_detail.client.name }}
            <div style="opacity: 0.6">
              {{ case_detail.client.user.mobile_display }} |
              {{ case_detail.client.user.email }}
            </div>
          </div>

          <div class="sub-title text-end">
            Case Id
            <div style="opacity: 0.6">
              {{ case_detail.case_id }}
            </div>
          </div>
        </div>

        <v-form class="py-2" lazy-validation ref="form">
          <div class="d-flex flex-column mt-6">
            <v-select
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              append-icon="mdi-chevron-down"
              v-model="form.reason"
              :items="reason_list"
              :rules="[$rules.required]"
              item-text="title"
              item-value="id"
              flat
              outlined
              dense
              label="Reason"
            ></v-select>

            <v-textarea
              v-if="form.reason == 0"
              flat
              row="2"
              outlined
              v-model="form.other_reason"
              :rules="[$rules.required]"
              label="Type here..."
            ></v-textarea>

            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="auto"
              offset-y
              nudge-top="20"
              v-if="[$keys.FOLLOW_UP, $keys.ON_HOLD].includes(type)"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-text-field 
                  readonly clearable
                  v-model="form.follow_up_date"
                  :rules="[$rules.required]"
                  label="Follow-up Date"
                  v-bind="attrs"
                  outlined
                  dense
                  append-icon="mdi-calendar"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                v-model="form.follow_up_date"
                no-title
                :min="minDate"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          :loading="btnLoading"
          class="text-capitalize px-8 my-2"
          height="40px"
          color="primary"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,
      reason_list: [],
      case_detail: null,
      dateMenu: false,
      form: {
        reason: null,
        other_reason: "",
        follow_up_date: "",
      },
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  computed: {
    ...mapGetters({
      caseCommonDialog: "dialogs/caseCommonDialog",
    }),

    type() {
      return this.caseCommonDialog.type;
    },

    show: {
      get() {
        return this.caseCommonDialog.show;
      },
      set(value) {
        this.toggleDialog({ show: value });
      },
    },
    minDate() {
      const tomorrow = this.$moment().add(1, "days");
      return tomorrow.format("YYYY-MM-DD");
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      toggleDialog: "dialogs/toggleCaseCommonDialog",
    }),

    async openModal() {
      this.loading = true;
      await this.getCaseDetail();
      await this.getReasonList();
      this.loading = false;
    },

    modalClosed() {
      this.case_detail = null;
      this.form = {
        reason: null,
        other_reason: "",
        follow_up_date: "",
      };
      this.toggleDialog({ show: false });
    },

    getReasonList() {
      const onSuccess = (res) => {
        this.reason_list = res.data.data;
        this.reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      const params = {
        reason_type: this.type,
      };

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },

    getCaseDetail() {
      const onSuccess = (res) => {
        this.case_detail = res.data.data;
      };

      let params = {};
      params["case_id"] = this.caseCommonDialog.caseId;

      return this.$request(this.$keys.GET, this.$urls.case.detail, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = {
          case: this.caseCommonDialog.caseId,
          follow_up_date: this.form.follow_up_date,
        };

        if (this.form.reason == 0) {
          data["other_reason"] = this.form.other_reason;
        } else {
          data["reason"] = this.form.reason;
        }

        if (this.type === this.$keys.FOLLOW_UP) {
          return this.$request(
            this.$keys.POST,
            this.$urls.case.moveToFollowUp,
            {
              data,
              onSuccess,
              onFinally,
            }
          );
        } else if (this.type === this.$keys.ON_HOLD) {
          return this.$request(this.$keys.POST, this.$urls.case.moveToOnHold, {
            data,
            onSuccess,
            onFinally,
          });
        } else if (this.type === this.$keys.IRRELEVANT) {
          return this.$request(
            this.$keys.POST,
            this.$urls.case.markAsIrrelevant,
            {
              data,
              onSuccess,
              onFinally,
            }
          );
        } else if (this.type === this.$keys.CANCELLED) {
          return this.$request(
            this.$keys.POST,
            this.$urls.case.close,
            {
              data,
              onSuccess,
              onFinally,
            }
          );
        } else return;
      }
    },
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}
.sub-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
</style>
