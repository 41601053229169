var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-xl",attrs:{"loading":_vm.loading,"loading-text":"Please wait ....Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3"},[_c('v-chip',{staticClass:"lighten-5 text--darken-2 font-weight-bold text-capitalize",class:item.status == 'verified'
            ? 'success success--text'
            : item.status == 'pending'
            ? 'blue blue--text'
            : 'error error--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status_display)+" ")])],1)]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"gap":"10px"}},[_vm._l((item.actions),function(action){return _c('div',{key:action.key},[(action.show)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","icon":"","disabled":action.disabled},on:{"click":function($event){return _vm.actionHandler(action.key, item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-"+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(action.text)+" ")]):_vm._e()],1)}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:("upload_" + (item.id)),attrs:{"type":"file","accept":"image/*, application/pdf"},on:{"change":function($event){return _vm.uploadFile(item)}}})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }